.page {
    position: relative;
    background: #fff;
    padding-bottom: 4rem;

    .page-header {
        .ui.header {
            font-family: Roboto;
            font-weight: 900;
            font-size: 1.6667em;
            letter-spacing: 0.146em;
            color: #2C315B;
        }
    }

    .page-content {
        padding: 2rem 0;
        font-family: Roboto;
        font-weight: 300;

        line-height: 1.6;
        h2.ui.header {
            font-family: Roboto;
            font-weight: 500;
            font-size: 1.2727em;
        }

        h3.ui.header {
            font-family: Roboto;
            font-style: italic;
            font-weight: 500;
            font-size: 1rem;
            letter-spacing: 0.035em;
        }
    }

    .page-section {
        padding-bottom: 2rem;

        &:last-child {
            padding-bottom: 0;
        }
    }
}