.country-reports {
    padding: 1em 0 2em;

    .country-report-links {
        display: flex;

        .link-container {
            padding-right: 2em;

            &.last-child {
                padding-right: 0;
            }
        }
    }
}

.ui.cards > .ui.card.report-card {
    font-family: Arial, Helvetica, sans-serif;
    background: rgb(232, 232, 232);

    .report-label {
        color: #0096A4;
    }
}