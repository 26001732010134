#methodology {
    .grid-item {
        padding: 2em 1.1em;

        .ui.image {
            width: auto;
            padding-bottom: 1.5rem;
            max-height: 125px;
        }
    }
}