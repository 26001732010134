@import 'roboto.scss';

#app {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-size: 15px;
    font-family: Roboto;

    p {
        line-height: 1.6;
    }

    a {
        color: #2DB29A;
        font-weight: 500;
        &:hover {
            text-decoration: underline;
        }
    }
}

.content {
    flex: 1;
}

// Fix for React-Vis grid lines
.rv-xy-plot__grid-lines__line {
  stroke: rgba(30,30,30,0.1);
  stroke-width: 1.0;
}

#dashboard {
    display: flex;
    flex-direction: column;
    .dashboard-content {
        position: relative;
        margin-left: 210px;
        padding: 1em;
    }
}
