#navigation {
    display: flex;
    flex-direction: column;

    @media (min-width: 480px) {
        justify-content: space-evenly;
        flex-direction: row;
    }

    user-select: none;
    padding: 2rem 0;

    .nav-dropdown {
        position: absolute;
        display: none;
        top: 100%;
        width: 100%;
        border-top: 2px solid #ccc;
        border-bottom: 1px solid #ccc;
        z-index: 9999;
        background: white;
        // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        box-shadow: 0px 2px 2px #ccc;

        a {
            color: black;
        }

        .dropdown-item {
            background: white;
            padding: 1rem 1rem;
            text-align: left;

            color: black;
            font-weight: 400;
            font-size: 1rem;
            letter-spacing: 0.15em;
        }
    }

    .nav-item {
        position: relative;
        font-family: Roboto;
        font-size: 1.26667rem;
        letter-spacing: 0.2em;
        flex: 1;
        text-align: center;
        border-top: 2px solid #ccc;
        border-bottom: 2px solid #ccc;
        padding: 1rem 0;
        cursor: pointer;
        margin-bottom: 1rem;

        @media (min-width: 480px) {
            &:first-child {
                margin-right: 2rem;
            }

            &:last-child {
                margin-left: 2rem;
            }
        }

        &.active {
            background: #2DB29A;
        }

        &:hover {
            background: #B7DDD8;
        }

        &.open {
            .nav-dropdown {
                display: block;
            }
        }
    }
}

@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {
        #navigation {
            margin-bottom: 15em;

            @media (min-width: 480px) {
                margin-bottom: 4rem;
            }

            .nav-item {
                padding-bottom: 2rem;
            }
        }
    }
}
