@import "_colors";

#header {
    padding: 1rem 0rem;
    padding-left: calc(100vw - 100%);
    margin: 0;
    flex-shrink: 0;
    flex-grow: 0;
    overflow-anchor: none;

    &.ui.menu {
        align-items: center;
    }
    .item {
        border: none;
        font-family: LatoWebBold, Arial, Helvetica, sans-serif;
        font-size: 1rem;
        text-transform: uppercase;
        &:before {
            background: none;
        }
    }

    .brand {
        font-family: LatoWebBold, Arial, Helvetica, sans-serif;
        font-size: 2rem;
        padding: 1rem 0;
        color: $primary;
        width: 100%;
        margin: 0 auto;
        padding: 0 1rem;

        @media (min-width: 768px) {
            width: 600px;
            margin: 0;
        }
        &:hover {
            background: transparent;
        }
    }

    box-shadow: none;
    border: none;

    .login-button {
        background: transparent;
        border-radius: 4px;
        border: 1px solid #2DB29A;
        color: #2DB29A;
        font-weight: 100;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;

        &:hover {
            color: white;
            background: #2DB29A;
        }
    }
}