@import "_colors.scss";

#oops {
    h1 {
        font-family: Roboto;
        font-weight: 900;
    }
    .homepage-btn {
        font-family: Roboto;
        font-weight: 300;
        background: none;
        border: 1px solid #2DB29A;
        transition: 0.3s ease-in-out;
        color: #2DB29A;

        &:hover {
            background: #2DB29A;
            color: #fff;
        }
    }
}