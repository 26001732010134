#footer {
  background: #0096A4;
  color: #fff;
  padding: 3rem 1rem;
  font-family: Roboto;
  font-weight: 300;
  line-height: 1.6;
  a {
      color: white !important;
      text-decoration: underline;
  }

  .footer-item {
      padding-bottom: 2rem;

      &:last-child {
          padding-bottom: 0;
      }
  }
}
