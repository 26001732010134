#contact-us {
    .ui.form {
        padding: 2em;
        background: #f5f5f5;
        border-radius: 4px;

        label {
            padding-bottom: 1em;
        }

        .contact-consent {
            margin: 2em 0 1em;
        }

        .ui.checkbox {
            padding-left: 0.5em;

            label {
                padding-left: 2em;

                &:before {
                    transform: scale(1.5);
                }
            }
        }
    }


    .ui.button {
        letter-spacing: 0.146em;
        background: #2DB29A;
        color: white;
        font-family: Roboto;
    }

    .contact-details {
        padding-top: 2rem;
    }

    #prove-human {
        label {
            display: block;
            padding-bottom: 1rem;

            &.captcha-error {
                color: red;
            }
        }

        .ui.input {
            width: 200px;
        }
    }
}