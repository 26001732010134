@import "_colors";

#home {
    position: relative;
    padding-bottom: 4rem;

    .home-header {
        padding-bottom: 1rem;
        .ui.header {
            font-family: Roboto;
            font-weight: 900;
            font-size: 1.6667em;
            letter-spacing: 0.146em;
            color: #2C315B;
        }
    }

    .map-disclaimer {
        font-family: Roboto;
        font-weight: 300;
        text-align: right;
        font-size: 1rem;
        font-style: italic;
        color: #ccc;
        padding: 0.5rem 0 2rem;
    }
}
