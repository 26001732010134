.disclaimer {
    margin-top: 2rem;
    background: #f5f5f5;
    padding: 2rem;
    border-radius: 4px;
    margin-bottom: 4rem;
    font-family: Roboto;
    font-weight: 300;

    .disclaimer-accept {
        padding: 2rem 0;
    }

    .disclaimer-button {
        padding: 1rem 0;
        .ui.button {
            letter-spacing: 0.146em;
            background: #2DB29A;
            color: white;
            font-family: Roboto;
        }
    }

    .ui.checkbox {
        padding-left: 0.5em;

        label {
            padding-left: 2em;

            &:before {
                transform: scale(1.5);
            }
        }
    }
}