.map-container {
  position: relative;
  height: 500px;

  // Hide Mapbox logo
  .mapboxgl-control-container {
    display: none
  }

  #view-default-view {
    color: white;
    cursor: pointer;
  }
}
