#cookie-consent-banner {
  background-color: #f5f5f5;
  border-top-style: solid;
  border-top-color: #999;
  border-top-width: thin;
  font-family: Roboto;
  z-index: 1000;
  
  .segment {
    border-radius: 0px;
  }

  .content {
    overflow: hidden;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .button {
    border-radius: 0px;
  }

}
